export default {
  de: {
    add: 'Hinzufügen',
    address: 'Adresse',
    address_title: 'Titel (optional)',
    app_install: 'App Installieren',
    app_install_not: 'Nicht Installieren',
    app_install_howto: 'Um die App zu installieren, klicke jetzt auf "Installieren" und folge den Anweisungen.',
    app_install_howto_android: 'Um die App zu installieren, klicke oben rechts neben der Adresse auf das Menu (drei Punkte) und dann auf "Zum Startbildschirm hinzufügen".\nDanach kannst du dieses Fenster schliessen und die App vom Home Screen aus starten.',
    app_install_howto_ios: 'Um die App zu installieren, klicke unten auf "Teilen" (Pfeil nach oben) und dann auf "Zum Home-Bildschirm".\nDanach kannst du dieses Fenster schliessen und die App vom Home Screen aus starten.',
    app_install_done: 'Super, die App ist in wenigen Sekunden installiert. Du kannst dieses Fenster schliessen und die App vom Home Screen aus starten.',
    archived_show: 'Archivierte anzeigen',
    archived_hide: 'Archivierte verstecken',
    back: 'Zurück',
    birthday: 'Geburtstag',
    camera: 'Kamera',
    category: 'Kategorie',
    category_choose: 'Kategorie / Kategorien wählen',
    category_categories: 'Kategorie / Kategorien',
    category_empty_placeholder: 'leer = in allen Kategorien',
    chat: 'Chat',
    chat_edit: 'Chat bearbeiten',
    chat_group: 'Chat Gruppe',
    chat_group_create: 'Chat Gruppe erstellen',
    chat_group_edit: 'Chat Gruppe bearbeiten',
    city: 'Ort',
    confirm_title: 'Bestätigung',
    confirm_ok: 'OK',
    confirm_cancel: 'Abbrechen',
    contact_save: 'Kontakt speichern',
    continue: 'Weiter',
    comment_delete: 'Kommentar löschen',
    correct: 'Richtig',
    created: 'Erstellt',
    date: 'Datum',
    del: 'Löschen',
    email: 'E-Mail',
    email_code_info: 'Du erhälst in Kürze eine E-Mail mit einem Bestätigungscode, gib diesen hier ein',
    email_reg_hint: 'Die E-Mail Adresse wird verifiziert',
    eye_tooltip: 'für alle sichtbar? grün=ja, rot=nein',
    file: 'Datei',
    files: 'Dateien',
    files_add: 'Dateien hinzufügen',
    forename: 'Vorname',
    gallery: 'Galerie',
    groups: 'Gruppen',
    group_members: 'Gruppen-Mitglieder',
    groups_empty_placeholder: 'leer = für alle sichtbar',
    groups_visibility: 'Gruppen (nur sichtbar für..)',
    has_new: 'Zum Aktualisieren tippen',
    hidden_events: '1 versteckter Event | {n} versteckte Events',
    hidden_post: '1 versteckter Beitrag | {n} versteckte Beiträge',
    img: 'Bild',
    imgs_more: 'Weitere Bilder',
    invite: 'Einladen',
    item_deleted: 'Dieser Eintrag wurde gelöscht',
    lastname: 'Nachname',
    login_and_contact: 'Login & Kontakt',
    letsgo: 'Los geht\'s!',
    name: 'Name',
    no: 'Nein',
    notifications: 'Benachrichtigungen',
    notification_settings: 'Benachrichtigungseinstellungen',
    no_more_entries: 'Keine weiteren Beiträge',
    no_unread_notifications: 'Keine ungelesenen Benachrichtigungen',
    mark_as_read_all: 'Alle als gelesen markieren',
    mobile_nr: 'Mobile-Nr',
    mobile_code_info: 'Du erhälst in Kürze eine SMS mit einem Bestätigungscode, gib diesen hier ein',
    mobile_reg_hint: 'Die Mobile-Nr wird per SMS verifiziert.',
    moderated_post: 'Moderiert (wird geprüft)',
    moderation_hint: 'Der Beitrag wird vor dem Publizieren überprüft.',
    objected: 'Markiert',
    password: 'Passwort',
    password_change: 'Passwort ändern',
    password_forgotten: 'Passwort vergessen',
    password_hint: 'Mind. 6 Zeichen',
    password_repeat: 'Passwort wiederholen',
    password_reset: 'Passwort zurücksetzen',
    passwords_not_matching: 'Passworte nicht identisch',
    player_url: 'URL zu MP3 Datei oder Youtube Video',
    player_file: 'Datei (mp3/pdf)',
    plz: 'PLZ',
    post_delete: 'Beitrag löschen',
    post_foreign_warning: 'Du hast Themen gewählt, die Du selber nicht abonniert hast. Deshalb wirst du den Beitrag auch selber nicht sehen, solange du nicht mindestens eines dieser Themen abonnierst.',
    profile_choose_img: 'Profilbild wählen',
    profile_name_hint: 'Der Name der überall angezeigt wird, kann auch ein Übername oder eine Abkürzung sein. Vorschlag: ',
    push_activate_q: 'Push Nachrichten aktivieren?',
    recipient: 'Empfänger',
    registration_accept: 'Akzeptieren und Zugang zur App erlauben',
    registration_decline: 'Ablehnen und Zugang zur App verweigern',
    registration_requested: 'Login beantragt',
    remarks_internal: 'Bemerkungen (intern)',
    remove: 'Entfernen',
    reset_pw_mail_sent: 'Wir haben dir eine E-Mail zum setzen eines neuen Passworts gesendet. Nachdem du dort ein neues Passwort gewählt hast, kannst du dich hier damit anmelden.',
    save: 'Speichern',
    scheduled_post: 'Geplant',
    selfie: 'Selfie',
    sent: 'Versendet',
    settings: 'Einstellungen',
    sex: 'Geschlecht',
    sex_m: 'Männlich',
    sex_f: 'Weiblich',
    show: 'Anzeigen',
    show_more: 'Mehr',
    signin: 'Anmelden',
    signout: 'Abmelden',
    signup: 'Registrieren',
    signup_moderation_hint: 'Vielen Dank für deine Anmeldung - sobald diese geprüft wurde, geht es hier weiter!',
    skip: 'Überspringen',
    sms_code: 'SMS Code',
    sms_code_info: 'Du erhälst innert wenigen Sekunden eine SMS mit einem 6-stelligen Zahlencode.',
    sms_code_hint: '6-stelliger Zahlencode',
    source: 'Quelle',
    street: 'Strasse, Nr',
    subject: 'Betreff',
    subtitle: 'Untertitel',
    tags: 'Themen',
    tags_info: 'Es stehen neue Themen zur Auswahl',
    tags_subscribe: 'Abonniere alle Themen, die dich interessieren',
    tag: 'Thema',
    tags_choose: 'Thema / Themen wählen',
    tag_tags: 'Thema / Themen',
    tags_empty_placeholder: 'leer = an alle!',
    time: 'Zeit',
    title: 'Titel',
    telm: 'Tel Mobil',
    telp: 'Tel Privat',
    telw: 'Tel Geschäft',
    update_available: 'Ein Update ist verfügbar',
    updating: 'Update wird installiert..',
    users_pick: 'Person(en) wählen',
    yeargang: 'Jahrgang',
    yes: 'Ja',
    message_direct: 'Direktnachricht',
    message_group: 'Nachricht in Gruppenchat',
    new_post: 'Neuer Beitrag',
    new_post_review: 'Neuer Beitrag zur Prüfung',
    comment_my_post: 'Dein Beitrag wurde kommentiert',
    comment_my_comment: 'Ein Beitrag den du kommentiert hast wurde kommentiert',
    mention_me: 'Du wurdest erwähnt',
    signup_review: 'Neue Registration zur Prüfung',
    err_auth_email_already_in_use: 'Diese E-Mail Adresse wird bereits verwendet',
    err_auth_invalid_email: 'Die E-Mail Adresse ist ungültig',
    err_auth_invalid_phone_number: 'Die Mobile-Nr ist ungültig',
    err_auth_user_disabled: 'Konto deaktiviert',
    err_auth_user_not_found: 'Konto nicht gefunden',
    err_auth_wrong_password: 'Passwort ungültig',
  },
  fr: {
    add: 'Ajouter',
    address: 'Adresse',
    app_install: 'Installer l\'application',
    app_install_not: 'Ne pas installer',
    app_install_howto: 'Pour installer l\'application, clique maintenant sur "Installer" et suis les instructions.',
    app_install_howto_android: 'Pour installer l\'application, clique sur le menu (trois points) en haut à droite de l\'adresse, puis sur "Ajouter à l\'écran d\'accueil".\nTu peux ensuite fermer cette fenêtre et lancer l\'application depuis l\'écran d\'accueil.',
    app_install_howto_ios: 'Pour installer l\'application, clique sur "Partager" (flèche vers le haut) en bas, puis sur "Vers l\'écran d\'accueil".\nTu peux ensuite fermer cette fenêtre et lancer l\'application depuis l\'écran d\'accueil.',
    app_install_done: 'Super, l\'application est installée en quelques secondes. Tu peux fermer cette fenêtre et lancer l\'app depuis l\'écran d\'accueil.',
    archived_show: 'Voir les archives',
    archived_hide: 'Masquer les archives',
    back: 'Retour',
    birthday: 'Anniversaire',
    camera: 'Appareil ?',
    category: 'Catégorie ',
    category_choose: 'Sélectionner les catégories',
    category_categories: 'Catégorie / Catégories',
    category_empty_placeholder: 'Vide = Toutes les catégories',
    chat: 'Chat',
    chat_edit: 'Modifier le Chat',
    chat_group: 'Groupe Chat',
    chat_group_create: 'Créer un Chat groupe ',
    chat_group_edit: 'Modifier le Chat groupe',
    city: 'Lieu',
    confirm_title: 'Valider',
    confirm_ok: 'Ok',
    confirm_cancel: 'Annuler',
    contact_save: 'Enregistrer le contact',
    continue: 'Continuer',
    comment_delete: 'Effacer le commentaire',
    correct: 'Correct',
    created: 'Créé',
    date: 'Date',
    del: 'Supprimer',
    email: 'E-Mail',
    email_code_info: 'Tu recevras bientôt un e-mail avec un code de confirmation, saisis-le ici',
    email_reg_hint: 'L\'adresse e-mail est vérifiée',
    eye_tooltip: 'Visible pour tous ? vert=oui, rouge=non',
    file: 'Fichier',
    files: 'Fichiers',
    files_add: 'Ajouter des fichiers',
    forename: 'Prénom',
    gallery: 'Galerie',
    groups: 'Groupes',
    group_members: 'Membres du groupe',
    groups_empty_placeholder: 'vide = visible pour tous',
    groups_visibility: 'Groupes (visible uniquement par..)',
    has_new: 'Cliquez pour actualiser',
    hidden_events: '1 événement vérifié | {n} événements vérifiés',
    hidden_post: '1 message caché | {n} messages cachés',
    img: 'Image',
    imgs_more: 'Plus d\'images',
    invite: 'Inviter',
    item_deleted: 'Cette entrée a été supprimée',
    lastname: 'Nom de famille',
    login_and_contact: 'Login & contact',
    letsgo: 'C\'est parti !',
    name: 'Nom',
    no: 'Non',
    notifications: 'Notifications',
    notification_settings: 'Paramètres de notification',
    no_more_entries: 'Pas d\'autres messages',
    no_unread_notifications: 'Pas de notifications non lues',
    mark_as_read: 'Marquer comme lu',
    mark_as_read_all: 'Marquer tout comme lu',
    mobile_nr: 'Mobile-No',
    mobile_code_info: 'Tu recevras bientôt un SMS avec un code de confirmation, saisis-le ici',
    mobile_reg_hint: 'Le numéro de mobile est vérifié par SMS.',
    moderated_post: 'Modération (en cours de vérification)',
    moderation_hint: 'La contribution est vérifiée avant d\'être publiée.',
    objected: 'Rejeté ?',
    password: 'Mot de passe',
    password_change: 'Modifier le mot de passe',
    password_forgotten: 'Mot de passe oublié',
    password_hint: 'Au moins 6 caractères',
    password_repeat: 'Répéter le mot de passe',
    password_reset: 'Réinitialiser le mot de passe',
    passwords_not_matching: 'Les mots de passe ne sont pas identiques',
    player_url: 'URL du fichier MP3 ou de la vidéo Youtube',
    player_file: 'Fichier (mp3/pdf)',
    plz: 'Code Postal',
    post_delete: 'Post supprimé',
    post_foreign_warning: 'Tu as choisi des sujets auxquels tu n\'es pas abonné(e). C\'est pourquoi tu ne verras pas le message tant que tu ne seras pas abonné à au moins un de ces sujets.',
    profile_choose_img: 'Choisir une photo de profil',
    profile_name_hint: 'Le nom de ton profil peut être aussi un surmon ou une abréviation. Par exemple :',
    push_activate_q: 'Activer les notifications push ?',
    recipient: 'Récepteur',
    registration_accept: 'Accepter et autoriser l\'accès à l\'app',
    registration_decline: 'Refuser et interdire l\'accès à l\'app',
    registration_requested: 'Connexion demandée',
    remarks_internal: 'Remarques (internes)',
    remove: 'Supprimer',
    reset_pw_mail_sent: 'Nous t\'avons envoyé un e-mail pour que tu puisses définir un nouveau mot de passe. Après avoir choisi un nouveau mot de passe, tu peux t\'identifier ici.',
    recipient: 'Destinataire',
    save: 'Enregistrer',
    scheduled_post: 'Planifié',
    selfie: 'Selfie',
    sent: 'Envoyé',
    settings: 'Réglages',
    sex: 'Genre',
    sex_m: 'Homme',
    sex_f: 'Femme',
    show: 'Annonces',
    show_more: 'Voir plus',
    signin: 'S\'inscrire',
    signout: 'Se désinscrire',
    signup: 'S\'enregistrer',
    signup_moderation_hint: 'Merci pour ton inscription - elle va être vérifiée!',
    skip: 'Sauter',
    sms_code: 'Code SMS',
    sms_code_info: 'Tu recevras en l\'espace de quelques secondes un SMS contenant un code numérique à 6 chiffres.',
    sms_code_hint: 'Code numérique à 6 chiffres',
    source: 'Source ',
    street: 'Rue, numéro',
    subject: 'Sujet ',
    subtitle: 'Sous-titres',
    tags: 'Thèmes',
    tags_info: 'De nouveaux thèmes sont disponibles',
    tags_subscribe: 'Abonne-toi à tous les sujets qui t\'intéressent',
    tag: 'Thème',
    tags_choose: 'Choisir un thème',
    tag_tags: 'Thème/Thèmes',
    tags_empty_placeholder: 'Vide = a tous',
    time: 'Heure',
    title: 'Titre',
    telm: 'Tél mobile',
    telp: 'Tél Privé',
    telw: 'Tél Prof',
    update_available: 'Une mise à jour est disponible',
    updating: 'Mise à jour en cours d\'installation...',
    users_pick: 'Choisir une ou plusieurs personnes',
    yeargang: 'Année de naissance',
    yes: 'Oui',
    message_direct: 'Message personnel ?',
    message_group: 'Message dans le chat group',
    new_post: 'Nouveau post',
    new_post_review: 'Nouveau post a valider',
    comment_my_post: 'Ton post a été commenté',
    comment_my_comment: 'Un post que tu as commenté a été commenté',
    mention_me: 'Tu as été mentionné',
    signup_review: 'Examen d\'une nouvelle inscritpion',
    err_auth_email_already_in_use: 'Cette adresse e-mail est déjà utilisée',
    err_auth_invalid_email: 'L\'adresse e-mail n\'est pas valable',
    err_auth_invalid_phone_number: 'Le numéro de mobile n\'est pas valable',
    err_auth_user_disabled: 'Compte désactivé',
    err_auth_user_not_found: 'Compte introuvable',
    err_auth_wrong_password: 'Mot de passe non valide',
  },
}